@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary: #68BBF3;
    /* --primary: #429cd2; */
    --primaryLight: #BBE3FF;
    /* --primaryDark: #343a40; */

    --secondary:#e6aacf;
    /* --secondary: #bb81a5; */
    /* --secondaryLight: */
    /* --secondaryDark: */

    --highlight: #FC0909;
    --blured: rgba(0,0,0,0.40);

    --neutralDarker: #7A848B;
    --neutralDark: #0F1419;

    --neutral: #ffffff;
    
    --neutralLight: #d8d8d8;
    --neutralLighter: #E7E7E8;

    --neutralLightest: #d2d2d3;
    /* --neutralLightest: */
  }

* {
    box-sizing: border-box;
}


html {
    font-size: 0.625rem;
    overscroll-behavior: none;
}


body {
    margin: 0;
    font-family: 'Poppins', 'Arial', sans-serif;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1.6;
}

img {
    color: var(--primary);
    max-width: 100%;
    display: block;
}


button { /* prevent tab/click effect */
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    background-color: var(--primary);
    color: var(--neutral);
    border: none;
}

label {
    display: block;
}


/* =================
    Typography
================= */

.sky-icon-text {
    font-weight: 500;
    font-size: 1.4rem;
    cursor: pointer;
} 

.male-color {
    color: var(--primary)
}

.female-color {
    color: var(--secondary)
}

.sky-filter-btn {
    font-size: 1.6rem;
}

button, p {
    font-family: 'Poppins', 'Arial', sans-serif;
}


/* ==================
    General Layout
===================== */

#root {
    display: grid;
    height: 100vh;
    grid-template-rows: 7em auto 7em;
}


/* Header */

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0em 2.5em;
    z-index: 2;
}

.header-logo {
    max-height: 40px;
    cursor: pointer;
}

.header-icon {
    cursor: pointer;
}

.header-icon-burger {
    font-size: 3.2rem;
}

.header-icon-close {
    font-size: 3.8rem;
}

.menu {
    z-index: -1;
    margin-bottom: 0;
    grid-area: 2 / 1;
    overflow:hidden;
    -webkit-transition: background-color 150ms linear;
    -ms-transition: background-color 150ms linear;
    transition: background-color 150ms linear;
}

.menu-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    padding: 0 15%;
    margin: 0;
    height:100%;
    background-color: var(--neutral);
    position:relative;
    border-radius: 35px 35px 0 0;
    list-style-type: none;
}

.menu-handle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1.5em;
    cursor: pointer;
}

.menu-handle > * {
    border: 1.5px solid  var(--neutralLighter);
    border-radius: 1px;
    margin-bottom: 5px;
}

.menu-handle > hr:first-child {
    width: 80px;
}

.menu-handle > hr:last-child {
    width: 50px;
}

@keyframes back-change {
   from { background: rgba(0,0,0, 0) }
     to { background: rgba(0,0,0, .75) }
}


@keyframes back-change-reverse {
      from { background: rgba(0,0,0,.75) }
        to { background: rgba(0,0,0,0) }
 }

@media (max-width:650px) {
    .menu-bg-dark {
        background:rgba(0,0,0,.75);
        animation: back-change 0.3s linear;
    }

    .menu-bg-light {
        animation: back-change-reverse 0.3s linear;
    }
}




/* Container */

.main-container {
    grid-area: 2 / 1 / 3 / 1;
    padding: 0 5%;
    overflow-y: auto;
    background-color: var(--neutral);
}


@media (min-width:500px) {
    .main-container {
        padding: 0 2.5em;
    }
}

/* Sky-Filter */


.sky-filter {
    position: sticky;
    top: 0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 2px 0px;
    height: 4em;
    grid-area: 2 / 1 / 3 / 1;
    background-color: var(--neutral);
    align-self: self-start;
    z-index: 1;
    display: flex;
    justify-content: space-evenly;
    align-items:center;
}

.sky-filter-btn {
    border: none;
    padding: 0.4em 2em;
    border-radius: 25px;
    background-color: var(--neutralLighter);
    color: var(--neutralDark);
    transition: background-color 0.2s ease, color 0.2s ease;
}

.sky-filter-btn-active { 
    background-color: var(--neutralLight);
}

.vertical-line {
    display: none;
    width: 1px;
    margin: 1em;
    background-color: var(--neutralDarker);
    height: 100%;
    border-radius: 1px;
}

.filter-icon {
    display: none;
    font-size: 2.2rem;
    color: var(--neutralDarker);
}


/* Bar */

.bar {
    /* grid-row: 3; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    z-index: 30;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 0px 4px 0px;
    position: fixed;
    bottom: 0;
    height: 7em;
    width: 100%;
    background-color: var(--neutral);
}

.icon-wrapper {
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10em;
    height: 6em;
    border-radius: 4em;
    transition: background-color 0.5s ease;
}

@media (hover: hover) {
    /*prevent blue background on touch on touch devices */
    .icon-wrapper:hover:hover { 
        background-color: var(--neutralLighter);
    }
}

.bar-icon {
    grid-area: 1 / 1;
    font-size: 3.2rem;
    align-self: center;
    justify-self: center;
}

.bar-title {
    font-size: 1.4rem;
    margin-bottom: 0;
    margin-top: 0.3em;
    letter-spacing: 0.5px;
}

.bar-icon-heart {
    padding-top: 0.1em;
    font-size: 3.3rem;
}

.dark {
    color: var(--neutralDark);
}

.counter {
    display: flex;
    color: var(--neutral);
    font-size: 1.2rem;
    padding: 0.1em 0.6em;
}


.bar-icon-imprint {
    display:none;
}


@media (min-width: 650px) {
    #root {
        /* grid-template-rows: 8em auto; */
        grid-template-rows: 1fr;
        grid-template-columns: 12em auto;
    }

    .header {
        justify-content: center;
        box-shadow: none;
        grid-row: 1 / -1;
        grid-column: 1 / 2;
        align-items:start;
        padding: 2em 1.5em 0 1.5em;
        z-index: 50;
        height: fit-content;
    }

    .header-icon {
        display: none;
    }

    .header-logo {
        max-height: 60px;
    }

    .menu {
        display: none;
    }

    .sky-filter {
        grid-area: 2 / 2 / -1 / -1;
        padding: 1em 3em;
        top: 0;
        left: 12em;
        right: 1.5em;
        margin: 2em auto 0 auto;
        max-width: 450px;
        position: fixed;
        border-radius: 35px;
        justify-content: space-between;
    }

    .vertical-line, .filter-icon {
        display: block;
    }

    .main-container {
        grid-area: 1 / 2 / -1 / -1;
    }

    .bar {
        grid-row: 1 / 3;
        grid-column: 1 / 2;
        display: flex;
        flex-direction: column;
        padding: 7em 0 2em 0;
        gap: 1em;
        position: static;
        height: 100%;
    }

    .icon-wrapper {
        width: 11em;
        height: 7em;
        border-radius: 5.5em;
    }

    .bar-icon-imprint {
        display: flex;
        margin-top: auto;
        cursor: pointer;
    } 

    .bar-title {
        padding: 0 0.4em;
    }

    .sky-filter-btn:hover { 
        background-color: var(--neutralLight);
    }
}


/* Search */

.sky {
    background-color: var(--primaryLight);
    padding: 0;
}

.inner-sky {
    /* background-color: green; */
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    grid-auto-rows: 800px;
    max-width: 750px;
    margin: 0 auto;
    justify-content: center;
    padding-top: 50px;
}

.sky-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
}

.sky-container {
    /* background-color: aqua;
    border: 1px solid red; */
    position: relative;
    height: 100%;
    width: 250px;
    /* cursor: pointer; */
}

.sky-icon-container {
    width: 100px;
    height: 90px;
    display: grid;
    position: absolute;
    cursor: pointer;
}

.sky-icon-cloud {
    grid-area: 1 / 1;
    cursor: pointer;
}

.sky-icon-heart {
    grid-area: 1 / 1;
    margin-top: 0.8em;
    visibility: hidden;
    cursor: pointer;
}

.sky-icon-text {
    justify-self: center;
    align-self:center;
    padding-bottom: 0.2em;
    grid-area: 1 / 1;
    cursor: pointer;
    
}

.sky-icon-btn {
    grid-area: 1 / 1;
    background-color: transparent;
    cursor: pointer;
    border: none;
}

.sky-icon-btn-active {
    color: var(--neutral);
    cursor: pointer;
}

.visible {
    visibility: visible;
    cursor: pointer;
}

.hidden {
    visibility: hidden;
    cursor: pointer;
}

@media (min-width: 1000px) {
    .sky-wrapper:nth-child(3) {
        grid-column: span 2;
    }

    .sky-wrapper:nth-child(4n) {
        grid-column: span 2;
    }

    .sky-wrapper:nth-child(8n)  {
        justify-self: end;
    }
}

/* Loading */

#heart {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#heart img {
    width: 100px;
}
  
@keyframes heartFadeInOut {
    0% {transform: scale(1);}
    25% {transform: scale(.97);}
    35% {transform: scale(.9);}
    45% {transform: scale(1.1);}
    55% {transform: scale(.9);}
    65% {transform: scale(1.1);}
    75% {transform: scale(1.03);}
    100% {transform: scale(1);}
}

@keyframes cloudFadeIn {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }
    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes heartTitleFadeIn {
    0% {
        display: none;
        opacity: 0;
        transform: scale(1);
    }
    25% {
        display: block;
        opacity: 0;
        transform: scale(1.1);
    }
    100% {
        display: block;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes cloudTitleFadeIn {
    0% { opacity: 0; }
    30% { opacity: 0; }
    100% { opacity: 1; }
  }
  
#heart img.bottom { 
    animation-name: heartFadeInOut; 
    animation-iteration-count: infinite;
    animation-duration: 1.7s;
}

.cloudTitleFavourite {
    animation: cloudTitleFadeIn 0.3s;
}

.heartFavourite {
    animation: heartFadeInOut 1.2s;
}

.heartTitleFavourite {
    animation: heartTitleFadeIn 1.2s;
}


/* Favourites */


.favourite-btn-container {
    display: grid;
    grid-template-columns: 1fr minmax(0.5em, 2em) 1fr;
    padding: 4em 0;
    max-width: 34em;
    margin: 0 auto;
}


.favourite-name-text {
    display: flex;
    align-items: center;
    font-weight: 500;
}

.rating-icon {
    color: var(--highlight);
    margin: 0.2em;
}

.favourites {
    position: relative;
    margin-top: 2em;
    margin-bottom: 9em;
}

.favourites-list {
    display: grid;
    grid-template-columns:  minmax(10rem, 1fr) minmax(max-content, 1fr) 1fr;
    padding-bottom: 2em;
}

.delete-btn {
    display:flex;
    align-items: center;
    justify-content: end;
}

.delete-favourite-btn {
    margin-left: auto;
    text-align: center;
    background-color: var(--neutral);
    color: var(--neutralDarker);
    font-size: 1.6rem;
    font-weight: bold;
    width: 2em;
    border-radius: 1em;
    
}

.delete-favourite-btn:hover { 
    background-color: var(--neutralLight);
}

@media (min-width: 650px) {

    .favourite-name-text {
        justify-content: end;
    }

    .delete-btn {
        display: block;
    }

    .favourites-list{
        column-gap: 1em;
    }
}


/* Not Found */


.not-found-title {
    text-align: center;
    color: var(--neutral);
    font-size: 5rem;
    margin-bottom: 3rem;
}

.not-found-subtitle {
    text-align: center;
    color: var(--neutral);
    margin: -1em 0 0 0;
}

.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}

#cloud1 {
    height: 90px;
    align-self: start;
    margin: 3em 3em;
}

#cloud2 {
    height: 110px;
    align-self: end;
}

#cloud3 {
    height: 120px;
    align-self: start;
    margin: 2em 2em;
    display: none;
}

#cloud4 {
    height: 80px;
    align-self: end;
    margin-right: 4em;
    display: none;
}

.back-to-search-btn {
    display: inline-block;
    padding: 2em 4em;
    background-color: var(--neutral);
    border-radius: 40px;
    letter-spacing: 1px;
    color:var(--primary);
    text-decoration: none;
    margin: 3em auto;
    transition: transform 250ms ease-in-out;
}

.back-to-search-btn:hover, .back-to-search-btn:focus {
    color: var(--neutral);
    background-color: var(--primary);
    transform: scale(1.02);    
}

@media (min-width: 650px) {
    .not-found-title {
        font-size: 15rem;
        margin-top: 0;
    }

    .not-found-subtitle { 
        font-size: 3rem;
    }

    #cloud3, #cloud4 {
        display: block;
    }
}

@media screen {
    .print-favourites-container {
        display: none;
    }
}


/* Pop Ups */

.pop-up-background {
    background: var(--blured);
    padding: 0 5%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.pop-up-container {
    background-color: var(--neutral);
    padding: 2.5em;
    border-radius: 1.5em;
    max-width: 50rem;
    margin: 0 auto;
}

.favourite-container {
    position: relative;
    z-index: 0;
}

.pop-up-text {
    width: 100%;
    margin-bottom: 0.5em;
    font-weight: 500;
}

input, select {
    width: 100%;
    /* margin-bottom: 2em; */
    height: 2.5em;
    padding-top: 0.2em;
    border-radius: 5px;
    padding-left: 0.5em;
    vertical-align: middle;
    border: 1px solid var(--neutralLight);
    color: var(--neutralDarker);
    font-size: 1.4rem;
}

input::placeholder {
    font-style: italic;
    letter-spacing: 1px;
    color: var(--neutralLight);
}

option {
    color: var(--neutralDark);
}

.pop-up-btn-container {
    display: flex;
    justify-content: space-evenly;
    gap: 1em;
}